import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './index.module.scss';

function Points({ points = [] }) {
  return (
    <ul className={styles.points}>
      {points.map(({ title, text }, i) => (
        <li className={styles.point} key={i}>
          <p className={styles.point__title}>{title}</p>
          <p className={styles.point__text}>{text}</p>
        </li>
      ))}
    </ul>
  );
}

export default Points;

Points.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};
