import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../Title/Title';
import * as styles from './index.module.scss';
import ActionButtons from '../../ModelRegistryMain/ActionButtons';

function GetStarted({ title, text, buttonText }) {
  return (
    <section className={styles.getStarted}>
      <Title title={title} size="m" sizeStyle="l" />
      <ActionButtons />
    </section>
  );
}

export default GetStarted;

GetStarted.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.any,
  buttonText: PropTypes.string,
};
