import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import content, { getStarted } from './content';
import GetStarted from '../Solutions/GetStarted';
import Section from '../Solutions/Section';

function ExperimentTrackingMain() {
  const images = useStaticQuery(query);
  return (
    <main>
      {content.map(({ title, text, image, imageText, imageAlt }, i) => (
        <Section
          title={title}
          text={text}
          image={images[image] || image}
          imageText={imageText}
          theme={i % 2 === 0 ? 'blue' : 'orange'}
          imageAlt={imageAlt}
        />
      ))}
      <GetStarted {...getStarted} />
    </main>
  );
}

export default ExperimentTrackingMain;

const query = graphql`
  query getExperimentTrackingImages {
    studioModelRegistryExample: file(
      relativePath: { regex: "/studio-model-registry-preview.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    studioViewExample: file(relativePath: { regex: "/studio-view.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    studioPlotsAndChangesExample: file(
      relativePath: { regex: "/studio-plots-and-changes.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    vscodeExample: file(
      relativePath: { regex: "/vscode-extension-example.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
  }
`;
