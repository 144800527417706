import React from 'react';
import BaseLink from '../BaseLink/BaseLink';
import iterativeDiagram from '../../images/iterative-diagram.svg';

export const points = [
  {
    title: 'Collaboration',
    text: 'Build and share custom views for discussion across your team',
  },
  {
    title: 'Reproducibility',
    text: 'Reproduce experiments in seconds, with full information around the entire pipeline',
  },
  {
    title: 'Visibility',
    text: 'See all experiments and models from development to production, with full explainability',
  },
];

export const getStarted = {
  title: 'Supercharge your ML experimentation with Iterative now!',
  buttonText: 'Contact Us',
};

const content = [
  {
    title: 'Track, visualize, and compare all experiments',
    text: 'Track your experiments with full experiment bookkeeping across code, data, hyperparameters, and metrics.',
    image: 'studioPlotsAndChangesExample',
    imageAlt:
      'An example of how our Studio product is able to compare and visualize experiments.',
    imageText:
      'See how your experiments have performed over time and compare metrics, plots, and more. Reproduce and re-run experiments instantly with access to all information about the experiment in one place.',
  },
  {
    title: 'Build custom dashboards and views',
    text: 'Increase transparency and share progress with customizable views.',
    image: 'studioViewExample',
    imageAlt: 'An example of our Studio product repo view.',
    imageText:
      'Choose with metrics, parameters, data, or any other information that matters to you and your team to build out custom views. Share these views and reports so your team can explore, compare, track progress, and more.',
  },
  {
    title: 'Link experiments to models and data',
    text: (
      <>
        Register and manage all your models, across the ML lifecycle in a single
        place with{' '}
        <BaseLink href="/model-registry">our model registry</BaseLink>.
      </>
    ),
    image: 'studioModelRegistryExample',
    imageAlt:
      "An example in Iterative's Studio product. Shows a page where you can view all your models.",
    imageText:
      'Collaborate across your teams with full model versioning and lineage. Register and promote models between stages, from development to production to retirement.',
  },
  {
    title: 'Experiment your way - CLI or GUI',
    text: 'Choose to work the way you like – in command line or with a GUI (or both!).',
    image: 'vscodeExample',
    imageAlt: 'TBD',
    imageText: (
      <>
        Point-and-click to run new experiments or compare and visualize them
        with <BaseLink href="https://studio.iterative.ai/">Studio</BaseLink>.
        Work within a CLI, Jupyter notebook, or even with{' '}
        <BaseLink href="https://marketplace.visualstudio.com/items?itemName=Iterative.dvc">
          our VS Code extension
        </BaseLink>{' '}
        that combines all your development work, experiment tracking, and data
        management in a single place.
      </>
    ),
  },
  {
    title: 'Combine with other Iterative tools (or your own)',
    text: 'Use Iterative tools for even more benefits - or integrate with your existing MLOps stack.',
    image: iterativeDiagram,
    imageAlt:
      'An example of the iterative.ai ecosystem. The example shows two layers, the bottom layer representing third part tools and the top layer represents iterative.ai products.',
    imageText:
      "Gain additional capabilities around data and experiment versioning, pipeline management, training automation, and more with Iterative tools. Iterative's products are modular, so you can pick-and-choose which to use or continue using your existing tools.",
  },
];

export default content;
