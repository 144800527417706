import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import SectionTitle from '../../SectionTitle/SectionTitle';
import * as styles from './index.module.scss';

function Section({ title, imageText, imageAlt = '', text, image, theme }) {
  return (
    <section className={styles.section}>
      <SectionTitle
        sectionLineTheme={theme}
        title={title}
        className={styles.section__title}
      />
      <p className={styles.section__text}>{text}</p>
      <div className={styles.section__image}>
        {getImage(image) ? (
          <GatsbyImage
            className={styles.img}
            alt={imageAlt}
            image={getImage(image)}
          />
        ) : (
          <img
            className={styles.img}
            alt={imageAlt}
            src={image}
            width="450"
            height="300"
          />
        )}
        <p>{imageText}</p>
      </div>
    </section>
  );
}

export default Section;

Section.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  imageAlt: PropTypes.string,
  theme: PropTypes.oneOf(['orange', 'blue']),
};
