import React from 'react';
import Layout from '../components/Layout/Layout';
import ExperimentTrackingMain from '../components/ExperimentTrackingMain';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import Points from '../components/Solutions/Points';
import Footer from '../components/Footer/Footer';
import RequestDemo from '../components/RequestDemo';
import { points } from '../components/ExperimentTrackingMain/content';

function ExperimentTracking() {
  return (
    <Layout title="Experiment Tracking for Machine Learning">
      <Header>
        <PageTitle
          title="Experiment Tracking for Machine Learning"
          text={
            <>
              Track and compare your experiments alongside data, metrics, and
              models, all based on your Git repositories -{' '}
              <strong>no additional tools needed.</strong>
            </>
          }
        />
        <RequestDemo text="Contact us!" />
        <Points points={points} />
      </Header>
      <ExperimentTrackingMain />
      <Footer />
    </Layout>
  );
}

export default ExperimentTracking;
