import React from 'react';
import ButtonLink from '../../Button/ButtonLink';
import RequestDemo from '../../RequestDemo';

const ActionButtons = () => {
  return (
    <div className={`flex flex-col md:flex-row mt-4 md:mt-6 lg:mt-8`}>
      <ButtonLink
        className={`my-5 md:m-0 md:mr-5`}
        type="orange-gradient"
        href={'https://studio.iterative.ai'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Sign Up
      </ButtonLink>
      <RequestDemo />
    </div>
  );
};

export default ActionButtons;
